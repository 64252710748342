import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import Style from './index.module.scss'

const Page = () => (
  <div className={Style.markdownBody} style={{ margin: '16px' }}>
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>TermsOfService</title>
      <link
        rel="icon"
        type="image/x-icon"
        href={`${withPrefix('/')}img/recipe-note-icon.png`}
      />
    </Helmet>
    <h1>ヘルプ</h1>
    <section>
      <h2>Webのレシピ画像をうまく保存できない</h2>
      <p>
        動画や広告など、特殊な画像の場合取り込めない可能性があります。以下を試してみてください。
      </p>
      <ol>
        <li>
          Webページをスクロールし、Webページ内の画像がすべて表示されたことを確認する
        </li>
        <li>レシピを保存する</li>
      </ol>
    </section>
    <section>
      <h2>Webのレシピの材料が自動取り込みされない</h2>
      <p>
        サイトによっては材料の自動取り込み機能がサポートされていない場合があります。対象のWebサイトのサポートをお待ち下さい。
      </p>
    </section>
    <section>
      <h2>バックアップ機能はありますか？</h2>
      <p>
        Google Driveを利用したバックアップ機能をご利用いただけます。
        バックアップ先のGoogle Driveにレシピデータを保存できる容量が充分にあることを確認し、
        バックアップを行ってください。データの保存容量は端末のアプリ情報から知ることができます。
      </p>
    </section>
    <section>
      <h2>バックアップしたデータはGoogle Driveのどこにありますか？</h2>
      <p>
        Google DriveのRECIPE NOTE専用の格納場所に保存されています。
        保存されているデータは非表示データとなるため閲覧できません。
        保存されているデータサイズの閲覧と削除のみ行うことができます。
        下記手順に従って参照してください。
      </p>
      <ol>
        <li>
          ブラウザでdrive.google.comを開く
        </li>
        <li>設定アイコンをクリックし、設定を開く</li>
        <li>アプリの管理をクリックする</li>
        <li>RECIPE NOTEの項目を見つける</li>
      </ol>
    </section>
    <section>
      <h2>
        バックアップ / 復元中に通信エラーが起きました。
        その後も頻繁に通信エラーになります。どうすればいいですか？
      </h2>
      <p>下記手順を行ってください。</p>
      <ol>
        <li>
          ログインしているアカウントからログアウトする。再度ログインする。
        </li>
        <li>もう一度バックアップ または　復元を行う</li>
        <li>「バックアップ/復元」画面を開いたままにする</li>
      </ol>
    </section>
    <section>
      <h2>お問い合わせ</h2>
      <p>
        本アプリに関するお問い合わせは、
        <a href="mailto:makitsystem@gmail.com">makitsystem@gmail.com</a>
        までお願いします。
      </p>
    </section>
  </div>
)
export default Page
